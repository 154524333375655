import React from 'react';
import './css/UserCard.css'; // Add styles for dropdown
import { FaAddressBook, FaCalendarCheck, FaUser, FaBox, FaHeart,  FaPowerOff, FaShoppingBag } from 'react-icons/fa';

const UserCard = ({ onLogout }) => {
  return (
    <div className="dropdownnn-container">
     
    </div>
  );
};

export default UserCard;
