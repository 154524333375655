import React from 'react';
import logo from './img/logo3.png'; // Ensure the path is correct

const Invoice = ({ order, productDetails }) => {
  // Ensure the products array exists and is not empty
  const products = productDetails || []; // Use productDetails passed as prop

  // Calculate total quantity and total amount
  const totalQuantity = products.reduce((acc, product) => acc + (product.quantity || 0), 0);
  const grandTotal = products.reduce((acc, product) => acc + (product.prod_price || 0) * (product.quantity || 0), 0);


  const uniqueInvoiceNumber = `INV${order.unique_id}-${Date.now()}`;

  return (
    <div style={{ paddingTop: '20px', fontFamily: 'Arial, sans-serif', border:'1px solid black' }}>
      {/* Header Section */}
      <div style={{padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  {/* Left: Logo */}
  <div style={{ flex: '1', textAlign: 'left' }}>
    <img src={logo} alt="Company Logo" style={{ width: '150px' }} />
    {/* <h2 style={{ margin: '0' }}>Company Name</h2> */}

  </div>

  {/* Center: Contact Info */}
  <div style={{ flex: '2', textAlign: 'center' }}>
    <p>Contact Us: 123849549</p>
    <p>Email: enquiryoneclick@gmail.com</p>
  </div>

  {/* Right: Company Info */}
  <div style={{padding:'5px', flex: '1', textAlign: 'center', border:'1px dashed #333' }}>
    Tax Invoice:<p> #{uniqueInvoiceNumber}</p>
  </div>
</div>


      <hr />

      {/* Order Information Section */}
      <div style={{padding: '10px', display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div>
          <p>Order ID: #{order.unique_id}</p>
          <p>Order Date: {new Date(order.order_date).toLocaleDateString()}</p>
          <p>Invoice Date: {new Date().toLocaleDateString()}</p>
          <p>Printed Date: {new Date().toLocaleDateString()}</p>
        </div>
        <div>
          <p><strong>Billing Address:</strong></p>
          <p>Market Road, Marthandam,</p>
          <p>Kanyakumari, 629165</p>
          <p>Email: enquiryoneclick@gmail.com</p>
          <p>Phone: +91-9092206677</p>
        </div>
        <div>
          <p><strong>Shipping Address:</strong></p>
          <p>
            {order.shipping_address ? (
              order.shipping_address.split(',').map((line, index) => (
                <span key={index}>
                  {line.trim()}<br />
                </span>
              ))
            ) : (
              'N/A'
            )}
          </p>
        </div>
      </div>

      {/* <hr /> */}

      {/* Products Table */}
      <table style={{width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #000', padding: '5px' }}>Product</th>
            <th style={{ border: '1px solid #000', padding: '5px' }}>Qty</th>
            <th style={{ border: '1px solid #000', padding: '5px' }}>Tax</th>
            <th style={{ border: '1px solid #000', padding: '5px' }}>Total Price</th>
          </tr>
        </thead>
        <tbody>
  {products.map((product, index) => (
    <tr key={product.prod_id}>
      <td style={{ border: '1px solid #000', padding: '5px' }}>
        {product.prod_name || '-'} <br />
        (#{product.prod_id})
      </td>
      <td style={{ border: '1px solid #000', padding: '5px' }}>
        {product.quantity || '-'}
      </td>
      <td style={{ border: '1px solid #000', padding: '5px' }}>
        {product.tax || '-'}
      </td>
      <td style={{ border: '1px solid #000', padding: '5px' }}>
        ₹{(product.prod_price * (product.quantity || 1)) || '0'}
      </td>
    </tr>
  ))}
</tbody>

      </table>

      {/* <hr /> */}

      {/* Total Section */}
      <div style={{padding:'10px', display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
        <div>
          <p>Total Products: {totalQuantity}</p>
          <p>Total Amount: ₹{grandTotal}</p>
        </div>
        <div>
          <p>Grand Total: ₹{grandTotal}</p>
        </div>
      </div>

      <hr />
      <p style={{fontSize:'12px', fontFamily:'dancing, cursive '}}>This is a computer generated invoice, no signature riquired.</p>

    </div>
  );
};

export default Invoice;
