import React, { useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../../components/ApiUrl';
import './css/ProfileCard.css';
import { FaTimes } from "react-icons/fa"; // Import icons
import Swal from 'sweetalert2'; // Ensure to import SweetAlert for notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CouponEditPopup = ({ isOpen, onClose, productId,productPrice, onCouponUpdated }) => {
  const [couponCode, setCouponCode] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  const handleAddCoupon = async () => {
    // Convert coupon code to uppercase
    const upperCouponCode = couponCode.toUpperCase();
  
    // Validate coupon code and extract numeric part
    const couponValueMatch = upperCouponCode.match(/\d+/); // Regex to find the first numeric part in the coupon code
    const couponValue = couponValueMatch ? Number(couponValueMatch[0]) : 0; // Get the number or default to 0 if not found
  
    // Check if either coupon code or coupon expiry date is provided
    if (
      (upperCouponCode && !expiryDate) ||
      (!upperCouponCode && expiryDate)
    ) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Both coupon code and coupon expiry date must be provided together or clear both.",
      });
      return;
    }
    
    if (
      (!upperCouponCode && !expiryDate) 
    ) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Please enter a coupon code like this (OFF899).",
      });
      return;
    }
  
    // Check if the coupon code is provided and not just spaces
    if (upperCouponCode && !upperCouponCode.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Coupon code cannot be just spaces.",
      });
      return;
    }
  
    // Ensure the extracted coupon value is less than the product price
    if (couponValue >= productPrice) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: `Coupon discount must be less than the product price. Product price is ${productPrice}`,
      });
      return;
    }
  
    // Check if the coupon code contains at least one letter and one digit
    const hasDigit = /\d/.test(upperCouponCode);
    const hasLetter = /[a-zA-Z]/.test(upperCouponCode);
  
    if (!hasDigit || !hasLetter) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Coupon code must contain at least one letter and one digit. (OFF899)",
      });
      return;
    }
  
    try {
      const coupon = {
        product_id: productId,
        coupon_code: upperCouponCode, // Store coupon code in uppercase
        discount_value: couponValue, // Set the extracted coupon value here
        expiry_date: expiryDate,
      };
  
      const response = await axios.post(`${ApiUrl}/multiplecoupons`, {
        coupons: [coupon],
      });
  
      if (response.status === 200) {
        // Success notification
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Coupon added successfully!',
        }).then(() => {window.location.reload();})
        // onCouponUpdated();
        onClose();
      }
    } catch (error) {
      console.error("Error adding coupon:", error);
      // Error notification
      Swal.fire({
        icon: 'error',
        title: 'Failed!',
        text: 'Failed to add coupon. Please try again.',
      });
    }
  };
  

  if (!isOpen) return null;

  // Get today's date and format it as YYYY-MM-DD
  const today = new Date();
  const minDate = today.toISOString().split("T")[0]; // Format to YYYY-MM-DD

  // Calculate max date (30 days from today)
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 30);
  const maxDateStr = maxDate.toISOString().split("T")[0]; // Format to YYYY-MM-DD


  const handleCouponCodeChange = (e) => {
    const value = e.target.value;

    // Allow only alphanumeric characters (letters and numbers)
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(value) || value === "") {
      setCouponCode(value);
    }
  };

  return (
    <div className="pop-overlay">
      <div className="pop-content">
        <h2>Add Coupon Code</h2>
        <input
          type="text"
          placeholder="Coupon Code"
          value={couponCode}
          onChange={handleCouponCodeChange} // Use the new change handler
          />
        <input
          type="date"
          placeholder="Expiry Date"
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
          min={minDate}
          max={maxDateStr}
        />
        <button className='coupon-btn' onClick={handleAddCoupon}>Add Coupon</button>
        <button className='close-button7' onClick={onClose}>
          <FaTimes color="black" size={20} />
        </button>
      </div>
    </div>
  );
};

export default CouponEditPopup;
